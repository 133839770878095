import {
  // AppExposedApis,
  // EditorSDK,
  EditorType,
} from '@wix/platform-editor-sdk';

let editorType;
export const editorReady = async (editorSDK, appDefinitionId, { origin }) => {
  editorType = origin?.type;

  const handler = (type, args) => {
    console.log(
      'Pro Gallery Editor script, Handler in editorScript called with type:',
      type,
      args,
    );
  };
  await editorSDK.addEventListener('aaa', (args) => handler('aaa', args));
  await editorSDK.addEventListener('connectedComponentDuplicated', (args) =>
    handler('connectedComponentDuplicated', args),
  );
  await editorSDK.addEventListener('connectedComponentAddedToStage', (args) =>
    handler('connectedComponentAddedToStage', args),
  );
  await editorSDK.addEventListener('connectedComponentPasted', (args) =>
    handler('connectedComponentPasted', args),
  );
  console.log('Pro Gallery Editor script, All handlers set');
};

export const onEvent = (event, editorSDK) => {
  console.log('Pro Gallery Editor script, onEvent', { event });
};

export const exports = (editorSDK) => ({
  editor: {
    beforeWidgetAdded: async (args) => {
      console.log('Pro Gallery Editor script, beforeWidgetAdded called ');
    },
  },
});

const createGallery = async (args, editorSDK) => {
  const { compId, originCompId, originInstanceId } = args;
  try {
    const requestUrl =
      'https://editor.wix.com/_api/pro-gallery-editor-webapp/v1/app/galleries'; // This was tested with editor. not editorx. might have CORS when we start testing it to
    const instance = await editorSDK.document.info.getAppInstance('token');

    const response = await fetch(requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: instance,
      },
      body: JSON.stringify({
        compId,
        originCompId,
        originInstanceId,
        editorType: convertEditorType(editorType),
      }),
    });
    const data = await response.json();
    if (typeof data?.galleryId === 'string') {
      return Promise.resolve();
    } else {
      throw new Error('no galleryId returned from the server');
    }
  } catch (e) {
    console.error(
      'Could not create a new gallery in the server using the provided data',
      JSON.stringify({
        compId,
        originCompId,
        originInstanceId,
        editorType: convertEditorType(editorType),
      }),
      e,
    );
  }
};

const convertEditorType = (type) => {
  switch (type) {
    case EditorType.Classic:
      return 'EDITOR';
    case EditorType.Responsive:
      return 'EDITORX';
    case EditorType.ADI:
      return 'ADI';
    default:
      // there are two more types in the possible EditorTypes, our server doesnt know about them
      return type;
  }
};
